<template>
  <div>
    Empty Page
  </div>
</template>

<script>
export default {
  name: "Empty",
  props: ["data"]
};
</script>

<style lang="scss" scoped>
div {
  background-color: black;
  width: 100%;
  height: 100%;
}
</style>
